import { EventType } from "EventObserver/EventPublisher";
import { EventSubscriber } from "EventObserver/EventSubscriber";
import { Elev8Api } from "Elev8Api/Elev8Api";
import Bugsnag from "@bugsnag/browser";

export class Elev8ApiWindowSizeEventSubscriber implements EventSubscriber {
  public receiveEvent(
    eventType: EventType,
    payload: { displayId: string; windowWidth: number; windowHeight: number }
  ): void {
    if (eventType !== EventType.WindowSize) {
      console.warn(
        "[Elev8ApiWindowSizeEventSubscriber] receiveEvent: invalid event type",
        eventType
      );
      return;
    }
    const { displayId, windowWidth, windowHeight } = payload;
    if (!displayId || !windowWidth || !windowHeight) {
      const errorMessage = `[Elev8ApiWindowSizeEventSubscriber] receiveEvent: invalid payload ${JSON.stringify(
        payload
      )}`;
      console.warn(errorMessage);
      Bugsnag.notify(new Error(errorMessage));
      return;
    }
    const eventSender = new Elev8Api();
    eventSender.sendWindowSize(displayId, windowWidth, windowHeight);
  }
}
