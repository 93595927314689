import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, { BugsnagErrorBoundary } from "@bugsnag/plugin-react";
import React from "react";
// You must build (npm run build/ci:checks/test) at least once to generate this file
import buildMeta from "../buildMeta.json";

interface BuildMeta {
  id: string;
}
const buildId: string = (buildMeta as BuildMeta).id;

function initializeBugsnag(): BugsnagErrorBoundary {
  const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY || "NOT_SPECIFIED";
  Bugsnag.start({
    apiKey: apiKey,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: ["production"],
    appVersion: buildId, // maybe should be metadata? https://docs.bugsnag.com/platforms/javascript/configuration-options/#metadata
  });
  const reactPlugin = Bugsnag.getPlugin("react");
  if (!reactPlugin) throw new Error("Bugsnag unable to get plugin: react");
  return reactPlugin.createErrorBoundary(React);
}

export default initializeBugsnag;
