import Content from "Content/Content";
import BigNotice from "./BigNotice";
import Alert from "./Alert";
import RssItem from "./RssItem";
import ImageItem from "./ImageItem";
import VideoItem from "./VideoItem";
import HtmlItem from "./HtmlItem";
import Loading from "./Loading";

class ContentComponentFactory {
  public static createComponent(content: Content): React.ReactElement {
    switch (content.type) {
      case "big_notice":
        return <BigNotice content={content} />;
      case "alert":
        return <Alert content={content} />;
      case "rss_feed":
        return <RssItem content={content} />;
      case "image":
        return <ImageItem content={content} />;
      case "video":
        return <VideoItem content={content} />;
      case "html":
        return <HtmlItem content={content} />;
      case "empty":
        return <></>;
      default:
        return <Loading />;
    }
  }
}

export default ContentComponentFactory;
