import type { EventSubscriber } from "./EventSubscriber";

// FIXME WIP:
export enum EventType {
  Play = "play",
  WindowSize = "windowSize",
  AppStart = "appStart",
  ErrorProofOfPlay = "errorProofOfPlay",
  ErrorContentService = "errorContentService",
  //Future event examples:
  // ServiceWorkerCached,
  // ServiceWorkerFetch,
  // ServiceWorkerInstall,
  // ServiceWorkerUpdate,
  // HttpCache,
  // WebVitals,
  // ErrServiceWorker,
  // ErrNetwork,
  // ErrVideoPlayback,
}

type SubscriptionsHash = Record<EventType, Array<EventSubscriber>>;

const _subscriptions: SubscriptionsHash = {} as SubscriptionsHash;

export function subscribe(
  eventType: EventType,
  subscriber: EventSubscriber
): Readonly<SubscriptionsHash> {
  console.log(`Subscribe: ${subscriber.constructor.name ?? "?"} to ${eventType ?? "?"} event`);
  const subscribers = _subscriptions[eventType] ?? [];
  const alreadyExists = subscribers.some((sub) => sub === subscriber);
  if (alreadyExists) {
    console.warn("EventSubscriber already attached.");
    return _subscriptions as Readonly<SubscriptionsHash>;
  }
  _subscriptions[eventType] = [...subscribers, subscriber];
  return _subscriptions as Readonly<SubscriptionsHash>;
}

//FIXME: conditional/overloaded type for payload based on the event type
// e.g. https://stackoverflow.com/a/67637668
export function notify(eventType: EventType, payload: object): void {
  const subscribers = _subscriptions[eventType] ?? [];
  for (const subscriber of subscribers) {
    subscriber.receiveEvent(eventType, payload);
  }
}

export function reset() {
  Object.keys(_subscriptions).forEach((key) => {
    // key was inferred as string because Object.keys always
    // returns string[], but we know it's an EventType.
    _subscriptions[key as unknown as EventType] = [];
  });
}
