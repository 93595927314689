import styles from "./PreviewTemplate.module.css";
import { useSpot } from "../Context";

const PreviewTemplate = () => {
  const {
    state: { environment },
  }: { state: any } = useSpot();
  if (environment === "preview")
    return (
      <div className={styles.previewContainer} data-testid="preview-banner">
        <h2>SCREEN PREVIEW</h2>
      </div>
    );
  return <></>;
};

export default PreviewTemplate;
