import { useEffect } from "react";
import { Params, useParams } from "react-router-dom";
import ContentProvider from "Content/Provider";
import SpotComponentFactory from "Content/SpotComponentFactory";
import { notify, EventType } from "EventObserver/EventPublisher";
import styles from "./ContentArea.module.css";
import { updateChecker } from "utils/UpdateChecker";
import PreviewTemplate from "Content/Templates/PreviewTemplate";

const ContentArea = () => {
  const { displayId } = useParams<Params>() as Params;
  if (!displayId) throw new Error("displayId is undefined");

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  useEffect(() => updateChecker(), []);

  useEffect(() => {
    notify(EventType.AppStart, { displayId });
    notify(EventType.WindowSize, { displayId, windowWidth, windowHeight });
  }, [displayId, windowWidth, windowHeight]);

  return (
    <main role="feed" className={styles.contentArea}>
      <ContentProvider>
        <PreviewTemplate />
        <SpotComponentFactory />
      </ContentProvider>
    </main>
  );
};

export default ContentArea;
