import ContentHtmlRenderer from "ContentComponents/ContentHtmlRenderer";
import Content from "./Content";
import { EventType, notify } from "EventObserver/EventPublisher";

class Spot {
  public static DEFAULT_LAYOUT_HTML =
    '<div data-content-area="main" style="height:100%;width:100%;"></div>';
  public static DEFAULT = new Spot(this.DEFAULT_LAYOUT_HTML, [Content.DEFAULT_CONTENT]);

  public layoutHtml: string;
  public contents: Array<Content>;

  constructor(layoutHtml: string, contents: Array<Content>) {
    this.layoutHtml = layoutHtml;
    this.contents = contents;
  }

  public renderHtml() {
    this.contents.forEach((content: Content) => {
      content.renderedHtml = ContentHtmlRenderer.render(content);
    });
  }

  public duration(): number {
    if (this.contents.length === 0) {
      console.warn("Spot.duration() invoked without content - using default value");
      return Content.DEFAULT_DURATION_SECONDS;
    }
    const maxDuration: number = Math.max(...this.contents.map((c: Content) => c.duration ?? 0));
    return maxDuration > 0 ? maxDuration : Content.DEFAULT_DURATION_SECONDS;
  }

  public mainContent(): Content | undefined {
    const mainContentArea = "main";
    if (!this.contents) return undefined;
    const result = this.contents.find((c: Content) => c.content_area === mainContentArea);
    if (!result) {
      notify(EventType.ErrorContentService, {
        source: "Spot.mainContent",
        context: { spot: this },
        message: `Could not find content with content_area: ${mainContentArea}; returning first content`,
      });
      return this.contents[0];
    }
    return result;
  }
}

export default Spot;
