import { AxiosError } from "axios";
import { DataPipelineApi } from "./DataPipelineApi";
import { DataPipelineEvent } from "./DataPipelineEvent";
import { DataPipelineEventPayload } from "./DataPipelineEventPayload";
import { InMemoryEventStorage } from "./InMemoryEventStorage";

const DEFAULT_UPLOAD_INTERVAL = 25 * 1000;
const DEFAULT_UPLOAD_BATCH_LIMIT = 20;

export class DataPipeline {
  private static displayId: string;
  private static eventStorage: InMemoryEventStorage;
  private static api: DataPipelineApi;
  private static intervalReference: NodeJS.Timeout;
  private static uploadBatchLimit: number = DEFAULT_UPLOAD_BATCH_LIMIT;

  private static setInterval() {
    if (this.intervalReference) clearInterval(this.intervalReference);
    let uploadInterval = Number(
      process.env.REACT_APP_DATA_PIPELINE_UPLOAD_INTERVAL ?? DEFAULT_UPLOAD_INTERVAL
    );
    if (uploadInterval < 10000) uploadInterval = 10000;
    this.intervalReference = setInterval(() => this.upload(), uploadInterval);
    console.log("DataPipeline.setInterval: ", uploadInterval);
  }

  private static setUploadBatchLimit() {
    let uploadBatchLimit = Number(
      process.env.REACT_APP_DATA_PIPELINE_UPLOAD_BATCH_LIMIT ?? DEFAULT_UPLOAD_BATCH_LIMIT
    );
    if (uploadBatchLimit < 1) uploadBatchLimit = DEFAULT_UPLOAD_BATCH_LIMIT;
    this.uploadBatchLimit = uploadBatchLimit;
    console.log("DataPipeline.setUploadBatchLimit: ", uploadBatchLimit);
  }

  static init(displayId: string, eventStorage: InMemoryEventStorage, api: DataPipelineApi) {
    console.log("DataPipeline.init");
    this.displayId = displayId;
    this.eventStorage = eventStorage;
    this.api = api;
    this.setInterval();
    this.setUploadBatchLimit();
    console.log("DataPipeline.init done", this.eventStorage);
  }

  static record(payload: DataPipelineEventPayload) {
    console.log("DataPipeline.record");
    const event = new DataPipelineEvent(payload);
    event.payload.display_id = this.displayId;
    this.eventStorage.insert(event);
  }

  private static upload() {
    console.log("DataPipeline.upload", this.eventStorage);
    const events = this.eventStorage.fetch(this.uploadBatchLimit);
    if (events.length === 0) {
      console.log("no events - skip");
      return;
    }

    const uuids = events.map((e) => e.uuid);
    this.api
      .uploadEvents(events)
      .then(() => {
        const preDeleteLength = this.eventStorage.length();
        this.eventStorage.delete(uuids);
        if (this.eventStorage.length() !== 0) {
          console.log(
            `DataPipeline.upload: events exceeded uploadBatchLimit (${preDeleteLength}/${this.uploadBatchLimit}). Uploading next batch...`
          );
          this.upload();
        }
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          console.error("[DataPipeline] upload AxiosError:", err.message, err.code, err.status);
        } else if (err instanceof Error) {
          console.error("[DataPipeline] upload ERR", err.name, err.message, err.cause);
        } else {
          console.error("[DataPipeline] unknown upload ERR", err);
        }
      });
  }
}
