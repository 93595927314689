import { useSpot } from "./Context";
import Loading from "ContentComponents/Loading";
import styles from "./SpotComponentFactory.module.css";
import SpotHtmlRenderer from "Content/SpotHtmlRenderer";
import ContentComponentFactory from "ContentComponents/ContentComponentFactory";

const SpotComponentFactory = () => {
  const {
    state: { spot, enableLayout, timezone, language },
  }: { state: any } = useSpot();
  const createLayoutDisplay = () => {
    const markup = { __html: SpotHtmlRenderer.render(spot, timezone, language) };
    return <div dangerouslySetInnerHTML={markup} className={styles.layoutContainer} />;
  };
  const createSingleContentDisplay = () => {
    return ContentComponentFactory.createComponent(spot.mainContent());
  };
  if (!spot) return <Loading />;
  return enableLayout ? createLayoutDisplay() : createSingleContentDisplay();
};

export default SpotComponentFactory;
