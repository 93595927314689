import Content from "../Content/Content";

enum PopStatus {
  OK = "OK",
  NOT_OK = "NOT_OK",
  FAIL = "FAIL",
  UNKNOWN = "UNKNOWN",
}
class DataPipelineEventPayload {
  display_id: string = "";
  app: string = "elev8-anywhere";
  event: string = "";
}

class DataPipelineAppStartEventPayload extends DataPipelineEventPayload {
  event: string = "app.start";
}

class DataPipelinePlayEventPayload extends DataPipelineEventPayload {
  event: string = "content.play";
  content: Content;
  env: string;

  constructor(content: Content, env: string) {
    super();
    this.content = content;
    this.env = env;
  }
}

class DataPipelinePopEventPayload extends DataPipelineEventPayload {
  event: string = "content.pop";
  provider: string;
  status: PopStatus;
  statusMessage: string;
  callback_url: string;
  creative_id: string = "";

  constructor({
    status,
    statusMessage,
    callback_url,
    provider,
    creative_id,
  }: {
    status: PopStatus;
    statusMessage: string;
    callback_url: string;
    provider: string;
    creative_id?: string;
  }) {
    super();
    this.status = status;
    this.statusMessage = statusMessage;
    this.callback_url = callback_url;
    this.provider = provider;
    this.creative_id = creative_id || "";
  }
}

class DataPipelineErrorEventPayload extends DataPipelineEventPayload {
  message: string;
  context: any;
  url: string;

  constructor(source: string, message: string, context?: any, url?: string) {
    super();
    this.event = `${source}.error`;
    this.message = message;
    this.context = context;
    this.url = url || "";
  }
}

export {
  PopStatus,
  DataPipelineAppStartEventPayload,
  DataPipelineErrorEventPayload,
  DataPipelineEventPayload,
  DataPipelinePlayEventPayload,
  DataPipelinePopEventPayload,
};
