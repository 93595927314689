import Content from "../Content";
import styles from "./TitleOnlyTemplate.module.css";

type Props = {
  content: Content;
};

const TitleOnlyTemplate = ({ content }: Props) => {
  const { title, image_url, signature_image_url } = content.content_data;

  return (
    <article className={styles.rssItem} style={{ backgroundImage: `url(${image_url})` }}>
      <div className={styles.logoContainer}>
        {signature_image_url && (
          <img className={styles.logo} src={signature_image_url} alt="Logo" />
        )}
      </div>
      <div className={styles.rssContent}>
        <div
          className={`${styles.logoAndTitleContainer} ${styles.titleContainerForWebkitLineClampPadding}`}
        >
          <div className={styles.logoContainerForWebkitLineClampPadding}></div>
          <h1 className={styles.title}>{title}</h1>
        </div>
      </div>
    </article>
  );
};

export default TitleOnlyTemplate;
