import Content from "./Content";
import Spot from "./Spot";

class SpotFactory {
  public static fromResponseData(data: any): Array<Spot> {
    return data.spots.map((rawSpot: any) => this.createSpot(rawSpot, data.layouts));
  }

  private static createSpot(rawSpot: any, layouts: any): Spot {
    const layout = layouts.find((layout: any) => layout.name === rawSpot.layout);
    if (!layout) {
      console.warn(`Spot.layout not found: ${rawSpot.layout}; using DEFAULT`);
      return Spot.DEFAULT;
    }
    return new Spot(layout.html, this.createContents(rawSpot));
  }

  private static createContents(rawSpot: any): Array<Content> {
    if (!rawSpot.contents) {
      console.warn("Spot.contents is undefined - using DEFAULT_CONTENT");
      return [Content.DEFAULT_CONTENT];
    }
    return rawSpot.contents.map((rawContent: any) => new Content(rawContent));
  }
}

export default SpotFactory;
