import defaultContentImage from "../Assets/default-content.jpg";
import { BIG_NOTICE_IMAGE_CONTENT_TYPE } from "ContentComponents/BigNotice";

class Content {
  public static DEFAULT_DURATION_SECONDS = 10;
  public static DEFAULT_CONTENT = new Content({
    content_area: "main",
    type: "big_notice",
    content_data: {
      content_type: BIG_NOTICE_IMAGE_CONTENT_TYPE,
      background_url: defaultContentImage,
    },
  });

  public content_area: string | null = null;
  public type: string | null = null;
  public duration: number = Content.DEFAULT_DURATION_SECONDS;
  public content_source: any = null;
  public content_data: any = null;
  public callback_url: any = null;

  public renderedHtml: string | null = null;

  public constructor(init?: Partial<Content>) {
    Object.assign(this, init);
  }
}

export default Content;
