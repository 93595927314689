import { EventType, notify } from "EventObserver/EventPublisher";
import { DataPipeline } from "DataPipeline/DataPipeline";
import { DataPipelinePopEventPayload, PopStatus } from "DataPipeline/DataPipelineEventPayload";
import fetchRetryWrapper from "fetch-retry";
import { fetchTimeout } from "utils/fetchTimeout";

const MAX_RETRIES_COUNT = 8;

type ContentSource = {
  provider?: string;
  creative_id?: string;
  campaign_id?: string;
  // id?: string;
  // lease_expiry?: string;
  // mime_type?: string;
};

export class ProofOfPlayManager {
  public async send(popUrl: string, content_source?: ContentSource): Promise<boolean> {
    let popStatus: PopStatus = PopStatus.UNKNOWN;
    let popStatusMessage = "";
    const fetchWithRetry = fetchRetryWrapper(fetchTimeout);
    console.log("[ProofOfPlayManager] proofOfPlay received: ", popUrl);
    try {
      const response = await fetchWithRetry(popUrl, {
        retries: MAX_RETRIES_COUNT,
        retryDelay: function (attempt, error, _) {
          console.warn(`[ProofOfPlayManager] retrying ${attempt}`, error);
          return Math.pow(2, attempt) * 1000;
        },
      });
      if (!response.ok) {
        this.handleProofOfPlayResponseError(response);
        popStatus = PopStatus.NOT_OK;
        popStatusMessage = `${response.status}: ${response.statusText}`;
        console.log(
          `[ProofOfPlayManager] proofOfPlay ${popStatus}: (${popStatusMessage}). ${popUrl}`
        );
        return false;
      }
      popStatus = PopStatus.OK;
      popStatusMessage = `${response.status}: ${response.statusText}`;
      console.log(
        `[ProofOfPlayManager] proofOfPlay ${popStatus}: (${popStatusMessage}). ${popUrl}`
      );
      return true;
    } catch (error) {
      this.handleProofOfPlayRequestError(error, popUrl);
      popStatus = PopStatus.FAIL;
      if (error instanceof TypeError) {
        popStatusMessage = `${error.name ?? "TypeError"}: ${error.message ?? "unknown"}`;
      } else popStatusMessage = "unknown";
      console.log(
        `[ProofOfPlayManager] proofOfPlay ${popStatus}: (${popStatusMessage}). ${popUrl}`
      );
    } finally {
      // finally is always called even if you throw OR return in try block
      DataPipeline.record(
        new DataPipelinePopEventPayload({
          status: popStatus,
          statusMessage: popStatusMessage,
          callback_url: popUrl,
          provider: content_source?.provider ?? "",
          creative_id: content_source?.creative_id ?? "",
        })
      );
    }
    return false;
  }

  private handleProofOfPlayResponseError(response: Response) {
    notify(EventType.ErrorProofOfPlay, {
      source: "ProofOfPlayManager.sendProofOfPlay",
      message: "Ad Response Error while posting proof of play",
      context: response,
      url: response.url,
    });
  }

  private handleProofOfPlayRequestError(error: unknown, url?: string) {
    notify(EventType.ErrorProofOfPlay, {
      source: "ProofOfPlayManager.sendProofOfPlay",
      message: "Ad proof of play, unexpected error occurred",
      context: error,
      url,
    });
  }
}
