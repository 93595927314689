import Content from "Content/Content";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";
import ContentComponentFactory from "./ContentComponentFactory";

class ContentHtmlRenderer {
  public static render(content: Content): string {
    let div: any = document.createElement("div");
    let root: any = createRoot(div);
    let component: any = ContentComponentFactory.createComponent(content);
    flushSync(() => {
      root.render(component);
    });
    const result = div.innerHTML;
    component = null;
    root.unmount();
    root = null;
    div = null;
    return result;
  }
}

export default ContentHtmlRenderer;
