import styles from "./Loading.module.css";
import "../Style/Typography.css";

const Loading = () => {
  return (
    <article className={styles.loading}>
      <h1 className={styles.title}>Loading...</h1>
    </article>
  );
};

export default Loading;
