export default class DateHelper {
  static formatTime(date: Date, timezone: string | undefined, language: string): string {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      timeZone: timezone ?? this.deviceTimezone(),
    };
    return new Intl.DateTimeFormat(this.locale(language), options).format(date).toUpperCase();
  }

  static formatDate(date: Date, timezone: string | undefined, language: string): string {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      month: "short",
      day: "numeric",
      timeZone: timezone ?? this.deviceTimezone(),
    };
    return new Intl.DateTimeFormat(this.locale(language), options).format(date);
  }

  static deviceTimezone(): string {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return timeZone;
  }

  static locale(language: string): string {
    const lookup: { [key: string]: string } = {
      en: "en-US",
      fr: "fr-CA",
      es: "es-MX",
    };
    return lookup[language] ?? "en-US";
  }
}
