import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./Style/Typography.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import OptionalStrictMode from "./Errors/OptionalStrictMode";
import initializeBugsnag from "./Errors/ErrorBoundary";
import Loading from "./ContentComponents/Loading";

import { subscribe, EventType } from "./EventObserver/EventPublisher";
import {
  DataPipelinePlayEventSubscriber,
  AdPlayEventSubscriber,
  DataPipelineAppStartEventSubscriber,
  Elev8ApiWindowSizeEventSubscriber,
  DataPipelineErrorEventSubscriber,
} from "EventObserver/EventSubscriber";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const ErrorBoundary = initializeBugsnag();
root.render(
  <OptionalStrictMode>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={Loading}>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </OptionalStrictMode>
);

subscribe(EventType.AppStart, new DataPipelineAppStartEventSubscriber()); //FIXME: this must be the first datapipeline subscriber
subscribe(EventType.WindowSize, new Elev8ApiWindowSizeEventSubscriber());
subscribe(EventType.Play, new DataPipelinePlayEventSubscriber());
subscribe(EventType.Play, new AdPlayEventSubscriber());

const dataPipelineErrorEventSubscriber = new DataPipelineErrorEventSubscriber();
subscribe(EventType.ErrorProofOfPlay, dataPipelineErrorEventSubscriber);
subscribe(EventType.ErrorContentService, dataPipelineErrorEventSubscriber);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); //TODO send this to pipeline!
