import Content from "Content/Content";
import { DataPipeline } from "DataPipeline/DataPipeline";
import { DataPipelinePlayEventPayload } from "DataPipeline/DataPipelineEventPayload";
import { type EventSubscriber } from "EventObserver/EventSubscriber";
import { EventType } from "EventObserver/EventPublisher";
import Bugsnag from "@bugsnag/browser";

//FIXME: implement a type check for the payload?
export class DataPipelinePlayEventSubscriber implements EventSubscriber {
  public receiveEvent(
    eventType: EventType,
    payload: { content: Content; environment: string }
  ): boolean {
    if (eventType !== EventType.Play) return false;
    const { content, environment } = payload;
    if (content === undefined || environment === undefined) {
      const errorMessage = `[DataPipelinePlayEventSubscriber] Invalid payload: ${JSON.stringify(
        payload
      )}`;
      Bugsnag.notify(new Error(errorMessage));
      return false;
    }
    const playEventPayload = new DataPipelinePlayEventPayload(content, environment);
    DataPipeline.record(playEventPayload);
    return true;
  }
}
