import SpotFactory from "./SpotFactory";
import Spot from "Content/Spot";
import { EventType, notify } from "EventObserver/EventPublisher";
import { fetchTimeout } from "utils/fetchTimeout";

const fetchSpots = async (displayId: string): Promise<Spot[]> => {
  const url = `${process.env.REACT_APP_CONTENT_API_BASE_URL}/screens/${displayId}/content/v2`;
  try {
    const response = await fetchTimeout(url);
    if (!response.ok) {
      throw response;
    }
    const data = await response.json();
    const spotList = SpotFactory.fromResponseData(data);
    if (spotList.length === 0) {
      notify(EventType.ErrorContentService, {
        source: "ContentService.fetchSpots",
        message: "No content returned",
        context: data,
        url,
      });
    }
    return spotList;
  } catch (error) {
    notify(EventType.ErrorContentService, {
      source: "ContentService.fetchSpots",
      message: "Unable to get new content",
      context: error,
      url,
    });
    return [];
  }
};

export default fetchSpots;
