import styles from "./HtmlTemplate.module.css";

type Props = {
  htmlContent: string;
};

const HtmlTemplate = ({ htmlContent }: Props) => {
  return (
    <article className={styles.htmlTemplate} dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default HtmlTemplate;
