import Spot from "Content/Spot";

const PERMITTED_DISPLAYS: Record<string, any> = {
  primary: ["imageNotice", "rss_feed"],
  secondary: ["textNotice"],
} as const;

export default class ContentFilterService {
  public static filterContent(spots: Array<Spot>, permittedDisplayRole: string | null) {
    return this.getFilteredContent(spots, permittedDisplayRole);
  }

  public static isValidDisplayRole(requestedDisplayRole: string | null) {
    if (!requestedDisplayRole) return false;
    return requestedDisplayRole in PERMITTED_DISPLAYS;
  }

  private static getFilteredContent(
    unfilteredSpots: Array<Spot>,
    permittedDisplayRole: string | null
  ) {
    if (!permittedDisplayRole) return unfilteredSpots;
    const permittedContentTypes = PERMITTED_DISPLAYS[permittedDisplayRole];

    const filteredContent = permittedContentTypes.reduce(
      (permittedContentList: Array<Spot>, contentType: string) => {
        const currentFilteredContent = this.getContent(contentType, unfilteredSpots);
        return [...currentFilteredContent, ...permittedContentList];
      },
      []
    );
    return filteredContent;
  }

  private static getContent(contentType: string, unfilteredSpots: Array<Spot>) {
    switch (contentType) {
      case "textNotice":
        return this.filterNoticeText(unfilteredSpots);
      case "imageNotice":
        return this.filterNoticeImages(unfilteredSpots);
      default:
        return this.filterContentType(unfilteredSpots, contentType);
    }
  }

  private static filterContentType(unfilteredSpots: Array<Spot>, typeFilter: string): Array<Spot> {
    return unfilteredSpots.filter((c) => c.mainContent()?.type === typeFilter);
  }

  private static filterNoticeImages(unfilteredSpots: Array<Spot>): Array<Spot> {
    return unfilteredSpots.filter(
      (content) =>
        content.mainContent()?.type === "big_notice" &&
        content.mainContent()?.content_data.content_type === "image"
    );
  }

  private static filterNoticeText(unfilteredSpots: Array<Spot>): Array<Spot> {
    return unfilteredSpots.filter(
      (content) =>
        content.mainContent()?.type === "big_notice" &&
        content.mainContent()?.content_data.content_type === "text"
    );
  }
}
