import { Route, Routes } from "react-router-dom";
import "./App.css";

import ContentArea from "./ContentComponents/ContentArea";
import InvalidDisplay from "./Errors/InvalidDisplay";

function App() {
  return (
    <Routes>
      <Route path="/display/:displayId" element={<ContentArea />} />
      <Route path="*" element={<InvalidDisplay />} />
    </Routes>
  );
}

export default App;
