import Spot from "./Spot";

export default class ContentLoop {
  static readonly LOAD_NEXT_THRESHOLD = 2;

  private contentList: Spot[];
  private contentQueue: Spot[] = [];

  constructor(contentList: Spot[]) {
    this.contentList = contentList;
    this.reset();
  }

  public shift(): Spot {
    const result = this.contentQueue.shift();
    if (!result) {
      console.warn(`ContentLoop empty; serving default placeholder`);
      const spot = Spot.DEFAULT;
      spot.renderHtml();
      return spot;
    }
    return result;
  }

  public length(): number {
    return this.contentQueue.length;
  }

  public almostEmpty(): boolean {
    return this.length() <= ContentLoop.LOAD_NEXT_THRESHOLD;
  }

  public empty(): boolean {
    return this.length() === 0;
  }

  // currently deleting shuffling - nightingale will handle ordering logic
  public reset() {
    // this.contentQueue = ContentLoop.shuffle(Array.from(this.contentList));
    this.contentQueue = Array.from(this.contentList);
  }

  /**
   * Shuffles an array of values, using the Fisher-Yates algorithm.
   *
   * See: https://stackoverflow.com/a/6274381
   *
   * @param {Array} list Array to be shuffled.
   * @returns {Array} Shuffled Array.
   */
  private static shuffle(list: Array<any>): Array<any> {
    for (let i = list.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
    return list;
  }
}
