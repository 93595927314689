import Content from "Content/Content";
import styles from "./Alert.module.css";

type Props = {
  content: Content;
};

const Alert = ({ content }: Props) => {
  return (
    <article className={styles.alert}>
      <div className={styles.textArea}>
        <h2 className={styles.title}>{content.content_data?.title}</h2>
        <div className={styles.body}>{content.content_data?.body}</div>
      </div>
    </article>
  );
};

export { Alert as default };
