import Bugsnag from "@bugsnag/browser";
import React, { ReactElement } from "react";
import Content from "Content/Content";
import ErrorArea from "../Errors/ErrorArea";
import styles from "./BigNotice.module.css";

type Props = {
  content: Content;
};

const BIG_NOTICE_TEXT_CONTENT_TYPE = "text";
const BIG_NOTICE_IMAGE_CONTENT_TYPE = "image";

const BigNotice = ({ content }: Props) => {
  return <article className={styles.bigNotice}>{bigNoticeContent(content)}</article>;
};

const bigNoticeContent = (content: Content): ReactElement => {
  const bigNoticeContentType = content.content_data?.content_type;
  if (bigNoticeContentType === BIG_NOTICE_TEXT_CONTENT_TYPE)
    return <BigNoticeText content={content} />;
  if (bigNoticeContentType === BIG_NOTICE_IMAGE_CONTENT_TYPE)
    return <BigNoticeImage content={content} />;
  return invalidBigNoticeContentType(bigNoticeContentType);
};

const BigNoticeText = ({ content }: Props) => {
  return (
    <React.Fragment>
      <div className={styles.titleContainerForWebkitLineClampPadding}>
        <h1 className={styles.title}>{content.content_data?.title}</h1>
      </div>
      <p className={styles.body}>{content.content_data?.body}</p>
    </React.Fragment>
  );
};

const BigNoticeImage = ({ content }: Props) => {
  return (
    <div className={styles.imageContainer}>
      <img src={content.content_data?.background_url} className={styles.image} alt="Big Notice" />
      <img
        src={content.content_data?.background_url}
        className={styles.imageBackground}
        alt="Big Notice Background"
      />
    </div>
  );
};

const invalidBigNoticeContentType = (bigNoticeType: string) => {
  const message = `Notice contains unrecognized content_type: ${bigNoticeType}`;
  console.warn(message);
  Bugsnag.notify(message);
  return <ErrorArea message={message} resolution="Please contact Vertical City support" />;
};

export { BigNotice as default, BIG_NOTICE_IMAGE_CONTENT_TYPE };
