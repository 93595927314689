import Content from "Content/Content";
import styles from "./HtmlItem.module.css";
type Props = {
  content: Content;
};

const HtmlItem = ({ content }: Props) => {
  const markup = { __html: content.content_data.html || "" };
  return <article className={styles.html} dangerouslySetInnerHTML={markup} />;
};

export default HtmlItem;
