import { createContext, useContext } from "react";
import Spot from "./Spot";

const useSpot = (): SpotContextType => {
  return useContext(SpotContext);
};

type SpotContextType = {
  state: {
    spot: Spot | undefined;
  };
  actions: {};
};

const SpotContext = createContext<SpotContextType>({
  state: {
    spot: undefined,
  },
  actions: {},
});

export { useSpot, type SpotContextType, SpotContext };
