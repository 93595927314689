import React from "react";

const OptionalStrictMode = ({ children }: { children: any }) => {
  if (process.env.REACT_APP_STRICT_MODE) {
    return <React.StrictMode>{children}</React.StrictMode>;
  } else {
    return <>{children}</>;
  }
};

export default OptionalStrictMode;
