import styles from "./ErrorArea.module.css";
import logo from "../Assets/vertical-city-logo.png";

type Props = {
  message: string;
  resolution: string;
};

const ErrorArea = (props: Props) => {
  return (
    <section className={styles.errorArea}>
      <div className={styles.content}>
        <img className={styles.logo} src={logo} alt="Vertical City Logo" />
        <hr className={styles.separator} />
        <p className={styles.message}>{props.message}</p>
        <p className={styles.resolution}>{props.resolution}</p>
      </div>
    </section>
  );
};

export default ErrorArea;
