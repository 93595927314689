import React, { useCallback, useEffect, useRef, useState } from "react";

const SpotTimer = (props: any) => {
  const [startTime] = useState(props.startTime);
  const onTimeout = props.onTimeout;
  const timeout = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    onTimeout();
  }, [onTimeout]);

  const timeoutRef = useRef<any>(undefined);
  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(timeout, props.duration);
  }, [props.duration, timeout, startTime]);

  return <React.Fragment key={startTime} />;
};

export default SpotTimer;
