import { v4 as uuidv4 } from "uuid";
import { DataPipelineEventPayload } from "./DataPipelineEventPayload";

export class DataPipelineEvent {
  public uuid: string;
  public start_at_utc: number;
  public start_at_local: string;
  public payload: DataPipelineEventPayload;
  public metadata: any;

  constructor(payload: DataPipelineEventPayload) {
    this.uuid = uuidv4();
    this.start_at_utc = Date.now();
    this.start_at_local = this.formatDateForBigQuery(new Date());
    this.payload = payload;
    this.metadata = undefined;
  }

  private formatDateForBigQuery(date: Date): string {
    // BigQuery expects DateTime format of `2022-12-07 14:27:09`,
    // one way to achieve that is the Swedish format.
    return date.toLocaleString("sv");
  }
}
