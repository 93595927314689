import Content from "../Content";
import styles from "./TitleDescriptionTemplate.module.css";

type Props = {
  content: Content;
};

const ItemDescription = ({ description }: any) => {
  if (!description || description.length === 0) {
    return null;
  }
  return (
    <div className={styles.descriptionContainerForWebkitLineClampPadding}>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

const TitleDescriptionTemplate = ({ content }: Props) => {
  const { title, description, image_url, signature_image_url } = content.content_data;
  return (
    <article className={styles.rssItem} style={{ backgroundImage: `url(${image_url})` }}>
      <div className={styles.logoContainer}>
        {signature_image_url && (
          <img className={styles.logo} src={signature_image_url} alt="Logo" />
        )}
      </div>
      <div className={styles.rssContent}>
        <div className={styles.titleContainerForWebkitLineClampPadding}>
          <h1 className={styles.title}>{title}</h1>
        </div>
        <ItemDescription description={description} />
      </div>
    </article>
  );
};

export default TitleDescriptionTemplate;
