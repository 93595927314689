import { DataPipeline } from "DataPipeline/DataPipeline";
import { DataPipelineAppStartEventPayload } from "DataPipeline/DataPipelineEventPayload";
import { type EventSubscriber } from "EventObserver/EventSubscriber";
import { EventType } from "EventObserver/EventPublisher";
import { InMemoryEventStorage } from "DataPipeline/InMemoryEventStorage";
import { DataPipelineApi } from "DataPipeline/DataPipelineApi";
import Bugsnag from "@bugsnag/browser";

//FIXME: implement a type check for the payload?
export class DataPipelineAppStartEventSubscriber implements EventSubscriber {
  public receiveEvent(eventType: EventType, payload: { displayId: string }): boolean {
    console.log("[DataPipelineAppStartEventSubscriber] receiveEvent", eventType, payload);
    if (eventType !== EventType.AppStart) return false;
    const { displayId } = payload;
    if (!displayId) {
      const errorMessage = `[DataPipelineAppStartEventSubscriber] Invalid payload: ${JSON.stringify(
        payload
      )}`;
      Bugsnag.notify(new Error(errorMessage));
      return false;
    }
    // FIXME: should not initialize DataPipeline here?
    // this is the right time to do it, but requires that this be the first
    // dataPipeline event subscribed to
    DataPipeline.init(displayId, new InMemoryEventStorage(), new DataPipelineApi());
    const playEventPayload = new DataPipelineAppStartEventPayload();
    DataPipeline.record(playEventPayload);
    return true;
  }
}
