import { useEffect, useRef } from "react";
import Content from "../Content/Content";
import styles from "./VideoItem.module.css";

type Props = {
  content: Content;
};

const VideoItem = ({ content }: Props) => {
  const { asset_url } = content.content_data;
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoNode = videoRef.current;
    forceMuteVideo(videoNode);

    return () => {
      cleanupVideoSrc(videoNode);
    };
  }, []);

  const forceMuteVideo = (videoNode: HTMLVideoElement | null) => {
    // Works around react bug where video.muted doesn't get rendered in HTML
    // https://github.com/facebook/react/issues/10389#issuecomment-1602142645
    if (videoNode) {
      videoNode.defaultMuted = true;
    }
  };

  const cleanupVideoSrc = (videoNode: HTMLVideoElement | null) => {
    // Workaround for a memory leak in legacy versions of Chrome
    // https://bugs.chromium.org/p/chromium/issues/detail?id=969049
    // https://github.com/facebook/react/issues/15583
    if (videoNode) {
      videoNode.src = "";
    }
  };

  // React does not reload elements so we need to explicitly restart the video on render.
  if (videoRef.current) {
    videoRef.current.currentTime = 0;
  }

  return (
    <article className={styles.videoContainer}>
      <video ref={videoRef} muted autoPlay loop src={asset_url} className={styles.videoItem} />
    </article>
  );
};

export default VideoItem;
