import Content from "../Content";
import styles from "./ImageOnlyTemplate.module.css";

type Props = {
  content: Content;
};

const ImageOnlyTemplate = ({ content }: Props) => {
  const { image_url, signature_image_url } = content.content_data;

  return (
    <div className={styles.imageContainer}>
      <img src={image_url} className={styles.image} alt="main" />
      <img src={image_url} className={styles.imageBackground} alt="background" />
      <div className={styles.logoContainer}>
        {signature_image_url && (
          <img className={styles.logo} src={signature_image_url} alt="Logo" />
        )}
      </div>
    </div>
  );
};

export default ImageOnlyTemplate;
