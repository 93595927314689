import ErrorArea from "./ErrorArea";

const InvalidDisplay = () => {
  return (
    <main>
      <ErrorArea
        message="Vertical City content URL is entered incorrectly."
        resolution="Please check your URL and try again."
      />
    </main>
  );
};

export default InvalidDisplay;
