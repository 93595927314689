import fetchRetryWrapper from "fetch-retry";
import { fetchTimeout } from "utils/fetchTimeout";

interface Configuration {
  environment: string;
  language: string;
  enable_layout: boolean;
  timezone: string | undefined;
}

const DEFAULT_CONFIG: Configuration = {
  environment: "production",
  language: "en",
  enable_layout: false,
  timezone: undefined,
};

const MAX_RETRIES_COUNT = 8;

const fetchConfiguration = async (displayId: string): Promise<Configuration> => {
  const CONFIG_URL = `${process.env.REACT_APP_CONTENT_API_BASE_URL}/api/screens/${displayId}/configuration`;
  const fetchWithRetry = fetchRetryWrapper(fetchTimeout);
  try {
    const response = await fetchWithRetry(CONFIG_URL, {
      retries: MAX_RETRIES_COUNT,
      retryDelay: function (attempt, error, _) {
        console.warn(`[ConfigurationService] retrying fetchConfiguration ${attempt}`, error);
        return Math.pow(2, attempt) * 1000;
      },
    });
    if (!response.ok) {
      console.error(
        `[ConfigurationService] Failed to get configuration. setting to default. ${response.status} ${response.statusText}`
      );
      return DEFAULT_CONFIG;
    }
    const config = await response.json();
    return validateConfig(config);
  } catch (error) {
    console.error(
      "[ConfigurationService] Unable to fetch configuration, setting to default.",
      error
    );
  }
  return DEFAULT_CONFIG;
};

function validateConfig(config: Configuration): Configuration {
  if (isConfig(config)) {
    console.log("[ConfigurationService] Valid configuration.", config);
    return config;
  }
  console.error("[ConfigurationService] Invalid configuration. Setting to default.", config);
  return DEFAULT_CONFIG;
}

// type predicate: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
function isConfig(config: Configuration | unknown): config is Configuration {
  const configOrNot =
    (config as Configuration) &&
    typeof (config as Configuration).environment === "string" &&
    typeof (config as Configuration).language === "string" &&
    typeof (config as Configuration).enable_layout === "boolean" &&
    typeof (config as Configuration).timezone === "string";
  return configOrNot;
}

export default fetchConfiguration;
