import DateHelper from "utils/DateHelper";
import Content from "./Content";
import Spot from "./Spot";

class SpotHtmlRenderer {
  public static render(spot: Spot, timezone: string | undefined, language: string): string {
    const layoutDiv = document.createElement("div");
    layoutDiv.innerHTML = spot.layoutHtml;
    this.insertTime(layoutDiv, timezone, language);

    spot.contents.forEach((content: Content) => {
      const targetDiv: HTMLElement | null = layoutDiv.querySelector(
        `[data-content-area="${content.content_area}"]`
      );
      if (!targetDiv) {
        console.warn(`Layout content_area not found: ${content.content_area}`);
        return;
      }
      targetDiv.innerHTML = content.renderedHtml ?? "";
    });
    return layoutDiv.innerHTML;
  }

  private static insertTime(
    layoutDiv: HTMLElement,
    timezone: string | undefined,
    language: string
  ) {
    const now = new Date();
    this.trySetContent(
      layoutDiv,
      `[data-local="time-time"]`,
      DateHelper.formatTime(now, timezone, language)
    );
    this.trySetContent(
      layoutDiv,
      `[data-local="time-date"]`,
      DateHelper.formatDate(now, timezone, language)
    );
  }

  private static trySetContent(div: HTMLElement, querySelector: string, content: string) {
    const element: HTMLElement | null = div.querySelector(querySelector);
    if (element) {
      element.innerHTML = content;
    }
  }
}

export default SpotHtmlRenderer;
