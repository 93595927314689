import Content from "Content/Content";
import styles from "./ImageItem.module.css";

type Props = {
  content: Content;
};

const ImageItem = ({ content }: Props) => {
  const { asset_url } = content.content_data;
  return (
    <article className={styles.ImageItem}>
      <ImageItemContent asset_url={asset_url} />
    </article>
  );
};

const ImageItemContent = ({ asset_url }: any) => {
  return (
    <div className={styles.imageContainer}>
      <img src={asset_url} className={styles.image} alt="main" />
      <img src={asset_url} className={styles.imageBackground} alt="background" />
    </div>
  );
};

export default ImageItem;
