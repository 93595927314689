import fetchRetryWrapper from "fetch-retry";
import { fetchTimeout } from "utils/fetchTimeout";

export class DataPipelineApi {
  MAX_RETRIES_COUNT = 4;

  async uploadEvents(events: Array<any>) {
    const url = process.env.REACT_APP_DATA_PIPELINE_UPLOAD_URL;
    if (!url) throw new Error("Undefined env: REACT_APP_DATA_PIPELINE_UPLOAD_URL");

    if (process.env.NODE_ENV === "development") {
      console.log("FAKE (not in prod) uploading events to", url, events);
      return;
    }

    const fetchWithRetry = fetchRetryWrapper(fetchTimeout);
    try {
      const response = await fetchWithRetry(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(events),
        retries: this.MAX_RETRIES_COUNT,
        retryDelay: function (attempt, error, _) {
          console.warn(`[DataPipelineApi] Retrying upload ${attempt}`, error);
          return Math.pow(2, attempt) * 1000;
        },
      });
      if (!response.ok) {
        console.error(
          `[DataPipelineApi] Failed to upload ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("[DataPipelineApi] Error uploading events", error);
    }
  }
}
