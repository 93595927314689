const DEFAULT_TIMEOUT = 30000; // 30 seconds for now, consider revisiting when elev8-central tightens up.

// optionally pass an external AbortController signal to the fetch call
// options are passed through to the fetch call
export const fetchTimeout = async (
  url: RequestInfo | URL,
  { signal = null as AbortSignal | null, ...options } = {} as RequestInit,
  timeoutMs: number = DEFAULT_TIMEOUT
) => {
  if ("AbortController" in window) {
    return fetchTimeoutUsingAbort(url, { signal, ...options }, timeoutMs);
  }
  return fetchTimeoutUsingRace(url, options, timeoutMs);
};

// AbortController >= Chrome 66, Safari 12.1, Firefox 57
async function fetchTimeoutUsingAbort(
  url: RequestInfo | URL,
  { signal = null as AbortSignal | null, ...options } = {} as RequestInit,
  timeoutMs: number = DEFAULT_TIMEOUT
) {
  console.debug(`[fetchTimeout] fetching with AbortController (${timeoutMs}ms)`, url);
  const errorMessage = `Fetch Abort Timeout (${timeoutMs}ms)`;

  const controller = new AbortController();
  const fetchPromise = fetch(url, { signal: controller.signal, ...options });
  if (signal) signal.addEventListener("abort", () => controller.abort(errorMessage));
  const timeoutId = setTimeout(() => controller.abort(errorMessage), timeoutMs);
  return await fetchPromise.finally(() => clearTimeout(timeoutId));
}

// THIS DOES NOT ACTUALLY CLOSE THE CONNECTION - it just ignores fetch promise resolution
async function fetchTimeoutUsingRace(
  url: RequestInfo | URL,
  options: RequestInit,
  timeoutMs: number = DEFAULT_TIMEOUT
) {
  console.debug(`[fetchTimeout] fetching with Promise Race (${timeoutMs}ms`, url);
  const errorMessage = `Fetch Race Timeout (${timeoutMs}ms)`;

  let timeoutId: NodeJS.Timeout;
  return Promise.race<Promise<Response>>([
    fetch(url, options),
    new Promise<Response>(
      (_, reject) => (timeoutId = setTimeout(() => reject(new Error(errorMessage)), timeoutMs))
    ).finally(() => clearTimeout(timeoutId)),
  ]);
}
