import ImageOnlyTemplate from "../Content/Templates/ImageOnlyTemplate";
import TitleDescriptionTemplate from "../Content/Templates/TitleDescriptionTemplate";
import TitleOnlyTemplate from "../Content/Templates/TitleOnlyTemplate";
import HtmlTemplate from "../Content/Templates/HtmlTemplate";

const RssItem = ({ content }: { content: any }) => {
  const createRssFeed = () => {
    if (content.content_data?.html) {
      return <HtmlTemplate htmlContent={content.content_data.html} />;
    }
    switch (content?.template) {
      case "title_only":
        return <TitleOnlyTemplate content={content} />;
      case "image_only":
        return <ImageOnlyTemplate content={content} />;
      default:
        return <TitleDescriptionTemplate content={content} />;
    }
  };
  return createRssFeed();
};

export default RssItem;
