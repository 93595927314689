import { EventType, notify } from "EventObserver/EventPublisher";
import ContentFilterService from "./ContentFilterService";
import ContentLoop from "./ContentLoop";
import fetchSpots from "./ContentService";
import Spot from "./Spot";

export default class ContentListCacheService {
  private static inMemoryContentCache: Array<Spot> = [];

  public static async loadLoop(
    displayId: string,
    permittedDisplayRole: string | null
  ): Promise<ContentLoop> {
    const newSpotList = await fetchSpots(displayId);
    if (newSpotList.length) {
      console.log("Fetch new content successful");
      const filteredContentList = ContentFilterService.filterContent(
        newSpotList,
        permittedDisplayRole
      );
      if (filteredContentList.length) {
        this.saveToCache(filteredContentList);
        return new ContentLoop(filteredContentList);
      }
      notify(EventType.ErrorContentService, {
        source: "ContentListCacheService.loadLoop",
        message: `No content present with the displayRole: ${permittedDisplayRole}`,
        context: newSpotList,
      });
    }
    console.log("Fetch new content failed");
    return new ContentLoop(this.loadFromCache());
  }

  private static saveToCache(contentList: Array<Spot>) {
    console.log("Saving to cache");
    this.inMemoryContentCache = contentList.slice();
  }

  private static loadFromCache(): Array<Spot> {
    console.log("Loading from cache");
    return this.inMemoryContentCache.slice();
  }
}
