import Bugsnag from "@bugsnag/browser";
import type Content from "Content/Content";
import { EventType } from "EventObserver/EventPublisher";
import { type EventSubscriber } from "EventObserver/EventSubscriber";
import { ProofOfPlayManager } from "ProofOfPlay/ProofOfPlayManager";

export class AdPlayEventSubscriber implements EventSubscriber {
  private proofOfPlayManager: ProofOfPlayManager;

  public constructor(proofOfPlayManager?: ProofOfPlayManager) {
    this.proofOfPlayManager = proofOfPlayManager ?? new ProofOfPlayManager();
  }

  public async receiveEvent(
    eventType: EventType,
    payload: { content: Content; environment: string }
  ): Promise<boolean> {
    if (eventType !== EventType.Play) return false;
    console.log("[AdPlayEventSubscriber] received Play event");
    const { content, environment } = payload;
    if (content === undefined || environment === undefined) {
      const errorMessage = `[AdPlayEventSubscriber] Invalid payload: ${JSON.stringify(payload)}`;
      Bugsnag.notify(new Error(errorMessage));
      return false;
    }
    if (this.isAnAd(content)) {
      console.log("[AdPlayEventSubscriber] IsAnAd. Sending to PoP Manager: ", content.callback_url);
      if (environment === "production") {
        return await this.proofOfPlayManager.send(
          content.callback_url,
          content.content_source ?? {}
        );
      }
      console.log(
        "[AdPlayEventSubscriber] Fake PoP callback (not production): ",
        content.callback_url
      );
      return true;
    }
    console.log("[AdPlayEventSubscriber] Not an Ad");
    return false;
  }

  /* currently, we consider an ad as any content with a callback_url */
  private isAnAd(content: Content): boolean {
    return content && content.callback_url?.length > 0;
  }
}
