export class InMemoryEventStorage {
  private storage: Array<any>;

  constructor() {
    this.storage = [];
  }

  insert(event: any) {
    this.storage.push(event);
  }

  fetch(limit: number): Array<any> {
    return this.storage.slice(0, limit);
  }

  delete(uuids: Array<string>) {
    this.storage = this.storage.filter((item: any) => !uuids.includes(item.uuid));
  }

  length(): number {
    return this.storage.length;
  }
}
